import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App.js';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { UserProvider } from './context/UserContext'

const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory({ window });
root.render(
  <HashRouter history={history}>
     <UserProvider>
      <App />
     </UserProvider>
  </HashRouter>
);
