import styled from "styled-components";
import { device } from "./Responsive.style.js";

export const FormInput = styled.div`
    position: relative;
    margin: 30px 0 0 0;

    input {
        font-size: 18px;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        min-width: 300px;
        border: 1px solid var(--text-main);
        border-radius: 5px;
        margin: 1px;
        color: var(--text-main);
        background-color: white;
    }

    label {
        color: var(--text-main);
        pointer-events: none;
        position: absolute;
        left: 12px;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        transition: top 0.2s, left 0.2s, font-size 0.2s, padding 0.2s;
    }

    input:focus {
        outline: none; 
        border: 2px solid var(--accent);
        margin: 0px;
    }

    input:focus ~ label,
    input:not(:focus):not(:placeholder-shown):not(:-webkit-autofill):invalid ~ label,
    input:not(:focus):not(:placeholder-shown):not(:-webkit-autofill):valid ~ label,
    input:-webkit-autofill ~ label {
        color: var(--accent);
        top: 0px;
        left: 7px;
        background-color: white;
        font-size: 14px;
        padding: 0 5px;
    }
    input:not([type="date"]):not(:focus):not(:placeholder-shown):not(:-webkit-autofill):invalid ~ label {
        color: red;
    }
    input:not([type="date"]):not(:focus):not(:placeholder-shown):not(:-webkit-autofill):invalid {
        border: 2px solid red;
        margin: 0px;
    }
    input:not(:focus):not(:placeholder-shown):not(:-webkit-autofill):valid ~ label,
    input:-webkit-autofill:not(:focus) ~ label {
        color: var(--text-main);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    input[type="date"] ~ label {
        color: var(--text-main) !important;
    }

    @media ${device.small} {
        label {
            color: var(--text-main);
            top: 0px;
            left: 7px;
            background-color: white;
            font-size: 14px;
            padding: 0 5px;
        }
    }
`;

export const Checkbox = styled.label`
    display: block;
    color: var(--text-main);
    position: relative;
    margin: 0;
    padding: 1px 0 0 28px;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .customCheckbox {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        border: 2px solid var(--accent);
        border-radius: 3px;
    }

    .customCheckbox::after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 2px;
        width: 3px;
        height: 7px;
        border: solid var(--accent);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    input:checked ~ .customCheckbox::after {
        display: block;
    }

    input:checked ~ .customCheckbox {
        background-color: #ffe7b5;
    }
`;

export const NormalInput = styled.input`
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    padding: 9px 11px;
    border-radius: 5px;
    border: 1px solid var(--text-main);
    margin: 2px 0;
    color: var(--text-main);
    background-color: white;

    &:focus {
        outline-color: var(--accent);
    }
`;

export const NumberInput = styled.input`
    box-sizing: border-box;
    font-size: 16px;
    width: 100px;
    padding: 9px 11px;
    border-radius: 5px;
    border: 1px solid var(--text-main);
    margin: 2px 0;
    color: var(--text-main);
    background-color: white;
    display: block;
    outline-color: var(--accent);
`;

export const Dropdown = styled.div`
    width: 350px;
    position: relative;
    z-index: 0;

    &::before, &::after {
        --size: 0.3rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }

    &::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    } 

    &::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }

    select {
        appearance: none;
        width: 100%;
        font-size: 16px;
        padding: 10px 95px 10px 16px;
        background-color: white;
        border: 1px solid var(--dark-bg-hover);
        border-radius: 5px;
        color: var(--text-main);
        cursor: pointer;
    }

    select:focus {
        outline-color: var(--accent);
    }

    @media ${device.medium} {
        width: 100%;
    }
`;

export const CheckboxDropdownWrapper = styled.div`
    width: 350px;
    position: relative;
    z-index: 4;

    &.dropdown-open {
        z-index: 15;
    }

    @media ${device.small} {
        width: 100%;
    }
`;

export const CheckboxDropdownBtn = styled.button`
    width: 100%;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    background-color: white;
    color: var(--text-main);
    position: relative;
    border: 1px solid var(--dark-bg-hover);
    padding: 10px 95px 10px 16px;
    font-size: 16px;

    &.btnOpen {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &.btnClose {
        border-radius: 5px;
    }

    &::before, &::after {
        --size: 0.3rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }

    &::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    } 

    &::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }
`;

export const CheckboxDropdownOptions = styled.div`
    background-color: white;
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    border: 1px solid var(--dark-bg-hover);
    border-top: none;
    position: absolute;
    box-sizing: border-box;
    width: 100%;

    &.closed {
        display: none;
    }

    &.open {
        display: flex;
    }

    input {
        display: none;
    }

    label {
        padding: 5px 5px 5px 15px;
        color: var(--text-main);
        cursor: pointer;
    }

    label:hover {
        background-color: var(--secondary-gray);
    }

    .optionSelected {
        position: relative;
    }

    .optionSelected::before {
        position: absolute;
        content: "";
        height: 90%;
        width: 10px;
        border-radius: 2px;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--accent);
    }
`;