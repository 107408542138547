import PageTransition from "../components/PageTransition.js";
import styled from "styled-components";

import dnaImg from "../assets/images/dna.jpeg";
import employersIcon from "../assets/images/employersIcon.svg";
import individualIcon from "../assets/images/individualIcon.svg";
import productIcon from "../assets/images/productIcon.svg";
import radarDiagram from "../assets/images/radarDiagram.png";

import { device } from "../styles/Responsive.style.js";
import AttributeInfo from "../components/Modals/AttributeInfo.js";
import { useState } from "react";

const Home = () => {
    const [moreInfo, setMoreInfo] = useState(false);

    return (
        <>
            <Hero bgImg={dnaImg}>
                <h1>digi<b>DNA</b></h1>
                <h2>Discovering your future.</h2>
            </Hero>

            <main>
                <Section className="product">
                    <LimitWidth className="textLeft">
                        <TextWrapper className="left">
                            <h3>The Product</h3>
                            <p>Introducing DigiDNA, a pioneering solution in the digital talent landscape, crafted with insights from industry, employers and testing specialists. In today's world, digital skills are not just valuable; they are essential. Yet, there's a glaring gap in the talent pool and a scarcity that's only growing.
                                <br/><br/>For employers, the question arises: Could the key to bridging this gap be hidden within your existing team? Is there untapped potential waiting to be discovered?
                                <br/><br/>For individuals, the thought of venturing into the digital realm might be intriguing, yet daunting. You might wonder, "Do I have the innate qualities to excel in this dynamic field?"
                                <br/><br/>DigiDNA is your compass in this exploration. 
                                <br/><br/>It's not just a questionnaire; it's a gateway to uncovering hidden talents. Designed to cater to those with minimal or no tech background, DigiDNA focuses on illuminating the inherent attributes that are critical for success in various digital roles - be it Software Development, Cyber Security, IT Support, Consulting, Data Science, AI, or even emerging roles like Prompt Engineering.
                                <br/><br/>Our tool has been tested across diverse sectors, including major financial institutions and leading global tech firms, with over a thousand learners. The feedback speaks volumes: "We were amazed to discover so many potential software developers within our own business"
                                <br/><br/>DigiDNA guides individuals and organisations to uncover the latent digital potential that lies within. 
                                <br/><br/>It's not just about identifying skills; it's about empowering transformation in the digital era. 
                            </p>
                        </TextWrapper>
                        <ImgWrapper>
                            <img src={productIcon} alt="individual icon"/>
                        </ImgWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth>
                        <ImgWrapper>
                            <img src={employersIcon} alt="company building"/>
                        </ImgWrapper>
                        <TextWrapper className="right">
                            <h3>For Employers</h3>
                            <p>In search of a nuanced and effective approach to talent assessment? DigiDNA offers a unique solution. 
                                <br/><br/>Our questionnaire can be customised to align with your organisation's specific needs, enhancing your ability to identify attributes in new hires that reflect your existing team's strengths. It serves as an instrument for discerning attributes in prospective candidates that are reflective of the core competencies within your team. 
                                <br/><br/>Engage with us to explore how DigiDNA can be customised for your enterprise, enhancing your talent acquisition strategy.</p>
                        </TextWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth className="textLeft">
                        <TextWrapper className="left">
                            <h3>For Individuals</h3>
                            <p>Discover the attributes sought after in the burgeoning digital economy with DigiDNA's questionnaire.  
                                <br/><br/>This tool is your guide to uncovering and showcasing the attributes that are in high demand in the digital sector. It offers a refined approach for individuals to demonstrate their innate strengths, simplifying the talent identification process for employers and positioning you as a standout candidate in the competitive digital landscape. 
                            </p>
                        </TextWrapper>
                        <ImgWrapper>
                            <img src={individualIcon} alt="individual icon"/>
                        </ImgWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth>
                        <ImgWrapper className="radar">
                            <img src={radarDiagram} alt="radar diagrams"/>
                        </ImgWrapper>
                        <TextWrapper className="right">
                            <h3>The Data</h3>
                            <p>See your results plotted on a radar chart to visually see which areas you are strong in and what areas you can improve on.
                                <br/><br/>You can also compare results against the averages to see how you scale in relation to the masses.
                            </p>
                        </TextWrapper>
                    </LimitWidth>
                </Section>
                <Section>
                    <LimitWidth className="textLeft attributes">
                        <TextWrapper className="left">
                            <h3>The Attributes</h3>
                            <p>DigiDNA was created with 4 base elements:
                                <br/><br/>1 - WAY - The traits that describe how people approach their work, their attitude and motivating forces
                                <br/>2 - KNOW - Knowledge based traits relating to how people understand and learn
                                <br/>3 - HUMAN - The way people interact and collaborate with others, teams and customers
                                <br/>4 - THINK - Traits that describe how people think about the world around them and the tasks they focus on
                                <br/><br/>The four base elements have been split into 10 Attribute Categories that make up the DigiDNA foundations, and it’s these categories which each question relates to.
                            </p>
                        </TextWrapper>
                        <RightSection>
                            <GridWrapper>
                                <div>
                                    <p>WAY</p>
                                    <ul>
                                        <li>Patient & Committed</li>
                                        <li>Efficiency & Improvement</li>
                                        <li>Resilient & Positive</li>
                                    </ul>
                                </div>
                                <div>
                                    <p>KNOW</p>
                                    <ul>
                                        <li>Curious & Inquisitive</li>
                                        <li>Detailed & Technically Minded</li>
                                    </ul>
                                </div>
                                <div>
                                    <p>HUMAN</p>
                                    <ul>
                                        <li>Communication & Empathy</li>
                                        <li>Team Player & Collaborative</li>
                                    </ul>
                                </div>
                                <div>
                                    <p>THINK</p>
                                    <ul>
                                        <li>Order & Logic</li>
                                        <li>Creative & Flexible</li>
                                        <li>Problem Solving</li>
                                    </ul>
                                </div>
                            </GridWrapper>
                            <p onClick={() => setMoreInfo(true)}>More Information</p>
                        </RightSection>
                    </LimitWidth>
                </Section>
                <ContactSection>Get in touch here to request an account:<br/><a href="mailto:digidna@wearecodenation.com?subject=Request Account">digidna@wearecodenation.com</a></ContactSection>
            </main>

            <AttributeInfo showModal={moreInfo} setShowModal={setMoreInfo}/>

            <PageTransition isLoading={false}/>
        </>
    )
}

export default Home;

const Hero = styled.header`
    position: relative;
    width: 100vw;
    height: 600px;
    background-image: url(${props => props.bgImg});
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 90px;
    box-sizing: border-box;

    h1, b, h2 {
        color: white;
        margin: 0;
        position: relative;
    }
    h1 {
        font-size: 64px;
        font-weight: 400;
    }

    h2 {
        font-size: 36px;
        font-weight: 400;
    }

    &::before {
        content: "";
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        height: 100%;
        width: 100%;
        left: 0;
    }

    @media ${device.medium} {
        height: 500px;
    }

    @media ${device.small} {
        height: 400px;
        padding: 0 30px;
        h1 {
            font-size: 48px;
        }

        h2 {
            font-size: 32px;
        }
    }
`;

const Section = styled.section`
    width: 100vw;
    padding: 0 50px;
    height: 450px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    &:nth-child(even){
        background-color: var(--primary-gray);
    }

    &.product {
        height: 650px;
    }

    @media ${device.medium} {
        padding: 0 20px;
    }

    @media ${device.small} {
        height: auto;

        &.product {
            height: auto;
        }
        .textLeft {
            flex-direction: column-reverse;
        }
        .attributes {
            flex-direction: column;
            margin: 20px 0;
        }
    }
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
        color: var(--blue);
        cursor: pointer;
        margin: 10px 0 0;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const LimitWidth = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    padding: 0 50px;

    .left {
        margin-right: 100px;
    }
    .right {
        margin-left: 100px;
    }
    .radar {
        max-width: 380px;
    }

    @media ${device.medium} {
        padding: 0;
        .left {
            margin-right: 30px;
        }
        .right {
            margin-left: 30px;
        }
    }

    @media ${device.small} {
        flex-direction: column;
        justify-content: center;

        .left {
            margin-right: 0;
        }
        .right {
            margin-left: 0;
        }

        .radar {
            max-width: 480px;
        }
    }
`;

const TextWrapper = styled.div`
    h3 {
        font-size: 36px;
        margin: 0;
        color: var(--accent);
    }
    p {
        color: var(--text-main);
    }

    @media ${device.small} {
        h3 {
            text-align: center;
        }
        p {
            text-align: center;
        }
    }
`;

const ImgWrapper = styled.div`
    width: 100%;
    max-width: 320px;
    margin: 50px 0;

    img {
        width: 100%;
    }

    @media ${device.small} {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        img {
            width: 50%;
        }
    }
`;

const GridWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 650px;

    div {
        width: 50%;
        background-color: rgba(245, 179, 47, 0.5);
        padding: 20px 10px;
        box-sizing: border-box;
    }

    div:nth-child(2), div:nth-child(3){
        background-color: rgba(245, 179, 47, 0.25);
    }

    p {
        margin: 0;
    }
    ul {
        margin: 0;
        padding: 0 0 0 27px;
    }

    @media ${device.small} {
        margin: 20px;
    }
`;

const ContactSection = styled.h4`
    text-align: center;
    font-size: 28px;
    padding: 25px 0;
    margin: 0;
    background-color: var(--primary-gray);

    a {
        font-weight: 400;
        text-decoration: none;
    }

    @media ${device.small} {
        font-size: 20px;
    }
`;