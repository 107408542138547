import styled from "styled-components";
import ModalTemplate from "../ModalTemplate";
import { PrimaryButton } from "../../../styles/Button.styles";
import { Checkbox, NormalInput } from "../../../styles/Form.styles";
import { useRef, useState } from "react";
import { createGroup } from "../../../utils";
import { useUser } from "../../../context/UserContext";
import { useAdmin } from "../../../context/AdminContext";

const AddGroup = ({ showModal, setShowModal, setOpenAddGroupSuccessModal }) => {
    const [selectedData, setSelectedData] = useState([]);
    const groupName = useRef();
    const groupDescription = useRef();

    const { setShowErrorModal } = useUser();
    const { adminData, setAdminData } = useAdmin();

    const handleSelectOption = (e) => {
        if(e.target.checked){
            setSelectedData(prev => [...prev, e.target.value])
        } else {
            setSelectedData(prev => [...prev.filter(o => o !== e.target.value)])
        }
    }

    const handleCreateGroup = async (e) => {
        e.preventDefault();
        const res = await createGroup(groupName.current.value, groupDescription.current.value, selectedData);

        groupName.current.value = ""
        groupDescription.current.value = ""
        setSelectedData([])
        if(res.message === "Successfully created group") {
            setShowModal(false);
            let adminCopy = {...adminData};
            adminCopy.groups = res.new_groups.groups;
            setAdminData(adminCopy);
            setOpenAddGroupSuccessModal(true);
        } else {
            setShowErrorModal(true);
            setShowModal(false);
        }

    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <h1>Create Group</h1>

            <form onSubmit={handleCreateGroup}>
                <h3>Group Name</h3>
                <NormalInput ref={groupName} placeholder="Enter Group Name" required/>

                <h3>Group Description</h3>
                <NormalInput ref={groupDescription} placeholder="Enter Group Description" required/>

                <h3>Data Capture</h3>
                <p>This is the data you want to capture for each user invited to this group. The invited users will be asked these questions when they sign up and won’t be able to sign up without answering these questions. <b>You will not be able to edit these questions after the group has been created.</b></p>
                <p>A new user is always required to input their name, email and password.</p>
                <p>Tick the boxes for the questions you want to ask to new users in this group.</p>

                <DataCaptureSection>
                    <Checkbox htmlFor="dob">
                        <input id="dob" value="dob" type="checkbox" onChange={handleSelectOption}/>
                        Date of Birth
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <Checkbox htmlFor="gender">
                        <input id="gender" value="gender" type="checkbox" onChange={handleSelectOption}/>
                        Gender
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <Checkbox htmlFor="education">
                        <input id="education" value="education" type="checkbox" onChange={handleSelectOption}/>
                        Highest Form of Education
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <Checkbox htmlFor="prevTechSkill">
                        <input id="prevTechSkill" value="prevTechSkill" type="checkbox" onChange={handleSelectOption}/>
                        Experience in Tech Industry
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <Checkbox htmlFor="status">
                        <input id="status" value="status" type="checkbox" onChange={handleSelectOption}/>
                        Employment Status
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <Checkbox htmlFor="region">
                        <input id="region" value="region" type="checkbox" onChange={handleSelectOption}/>
                        Region
                        <div className="customCheckbox"></div>
                    </Checkbox>
                    <Checkbox htmlFor="aspiration">
                        <input id="aspiration" value="aspiration" type="checkbox" onChange={handleSelectOption}/>
                        Aspirations in Tech Industry
                        <div className="customCheckbox"></div>
                    </Checkbox>
                </DataCaptureSection>

                <PrimaryButton type="submit">Create Group</PrimaryButton>
            </form>
        </ModalTemplate>
    )
}

export default AddGroup;

const DataCaptureSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 30px;

    label {
        width: 46%;
        margin: 5px 0;
    }
`;