import styled from "styled-components";
import { device } from "./Responsive.style.js";


export const TableWrapper = styled.table`
    border-collapse: collapse;
    color: var(--text-main);
    width: 100%;
    background-color: white;
    font-size: 16px;

    tr:hover {
        background-color: var(--primary-gray);
    }

    .reg {
        width: 60px;
    }
    .res {
        width: 150px;
    }

    @media ${device.small} {
        font-size: 14px;

        .reg {
            width: auto;
        }
        .res {
            width: auto;
        }
    }
`;

export const TableHeader = styled.th`
    text-align: left;
    padding: 10px 8px;
    background-color: #E8E8E8;
    font-weight: 900;
    position: relative;
    font-size: 20px;

    &:not(:last-child)::before {
        content: "";
        position: absolute;
        height: calc(100% - 8px);
        width: 2px;
        border-radius: 5px;
        background-color: #d6d6d6;
        top: 4px;
        right: 0;
    }

    @media ${device.small} {
        font-size: 16px;
    }
`;

export const TableRow = styled.tr`
    padding: 10px;
    position: relative;

    td {
        padding: 8px 20px;
        position: relative;
    }

    td:not(:last-child)::before {
        content: "";
        position: absolute;
        height: calc(100% - 10px);
        width: 1px;
        border-radius: 5px;
        background-color: #E3E3E3;
        top: 5px;
        right: 0;
    }

    &:not(:last-child)::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        border-radius: 5px;
        background-color: #E3E3E3;
        bottom: -1px;
        left: 0;
    }
    
    td:last-child {
        text-align: center;
    }

    p {
        font-weight: 900;
        color: var(--accent);
        margin: 0;
        cursor: pointer;
        padding: 5px 0;
    }

    .cross, .tick {
        height: 30px;
        width: 30px;
        position: relative;
        margin: 0 auto;
    }

    .tick::before {
        position: absolute;
        content: "";
        height: 25px;
        width: 3px;
        top: 3px;
        left: 18px;
        transform: rotate(45deg);
        border-radius: 5px;
        background-color: var(--green);
    }

    .tick::after {
        position: absolute;
        content: "";
        height: 12px;
        width: 3px;
        top: 14px;
        left: 7px;
        transform: rotate(-45deg);
        border-radius: 5px;
        background-color: var(--green);
    }

    .cross::before {
        position: absolute;
        content: "";
        height: 30px;
        width: 3px;
        top: 0px;
        left: 14px;
        transform: rotate(45deg);
        border-radius: 5px;
        background-color: var(--red);
    }

    .cross::after {
        position: absolute;
        content: "";
        height: 30px;
        width: 3px;
        top: 0px;
        left: 14px;
        transform: rotate(-45deg);
        border-radius: 5px;
        background-color: var(--red);
    }
`;