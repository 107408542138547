import { useEffect, useState } from "react";
import ModalTemplate from "./ModalTemplate.js";
import styled from "styled-components";
import { NumberInput } from "../../styles/Form.styles.js";
import { PrimaryButton } from "../../styles/Button.styles.js";
import { addInvites } from "../../utils/index.js";

const EditAdmin = ({ showModal, setShowModal, adminInfo, setAllAdmins, setSuccessInviteModal }) => {
    const [numOfInvites, setNumOfInvites] = useState(0);
    const [warningMsg, setWarningMsg] = useState("");

    useEffect(() => {
        setNumOfInvites(adminInfo.invites)
    },[adminInfo])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = await addInvites(adminInfo._id, numOfInvites);

        if(data.message === "Successfully updated invites"){
            setAllAdmins(prev => {
                let o = [...prev]
                o[o.findIndex(ad => ad._id === adminInfo._id)].invites = numOfInvites
                return o
            })
            setShowModal(false);
            setSuccessInviteModal(true);
            return
        }
        setWarningMsg(data.message)
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <AddWrapper>
                <h2>Edit Admin</h2>
                <p>You can't edit all of the Admin details and can only change how many invites they have left.</p>
                <h2>{adminInfo.name}</h2>
                <h3>{adminInfo.company} - {adminInfo.currentUsers} users already signed up</h3>

                <h4>Number of Invites:</h4>
                <p>This is how many invite requests the admin has remaining.</p>

                <form onSubmit={handleSubmit}>
                    {adminInfo.invites !== undefined && <NumberInput type="number" min={adminInfo.invites} max="999" value={numOfInvites} onChange={(e) => {if(e.nativeEvent.data !== "e") return setNumOfInvites(e.target.value)}}/>}
                    <p className="warningText">{warningMsg}</p>
                    <PrimaryButton type="submit">Save Changes</PrimaryButton>
                </form>
            </AddWrapper>
        </ModalTemplate>
    )
}

export default EditAdmin;

const AddWrapper = styled.div`
    h1 {
        margin: 0;
        font-size: 48px;
    }

    & > p {
        font-size: 18px;
        margin-top: 5px;
    }

    h2 {
        font-size: 32px;
        font-weight: 100;
        margin-bottom: 0;
    }
    h3 {
        font-size: 24px;
        font-weight: 100;
        margin-top: 0;
    }

    h4 {
        font-size: 18px;
        margin-bottom: 0;
    }

    form .warningText {
        font-weight: 900;
        color: var(--red);
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0;
    }
`;