import ModalTemplate from "../ModalTemplate";
import { PrimaryButton } from "../../../styles/Button.styles";
import { NormalInput } from "../../../styles/Form.styles";
import { useRef } from "react";
import { editGroup } from "../../../utils";
import { useUser } from "../../../context/UserContext";
import { useAdmin } from "../../../context/AdminContext";
import styled from "styled-components"

const EditGroup = ({ showModal, setShowModal, setEditSuccessModal, group_id }) => {

    const groupName = useRef();
    const groupDescription = useRef();

    const { setShowErrorModal } = useUser();
    const { adminData, setAdminData } = useAdmin();

    const handleEditGroup = async (e) => {
        e.preventDefault();
        const res = await editGroup(groupName.current.value, groupDescription.current.value, group_id);

        if(res.message === "Successfully updated group") {
            setShowModal(false);
            let adminCopy = {...adminData};
            let index = adminCopy.groups.findIndex(obj => obj._id === group_id)
            adminCopy.groups[index].title = groupName.current.value;
            adminCopy.groups[index].description = groupDescription.current.value;
            setAdminData(adminCopy);
            setEditSuccessModal(true);
        } else {
            setShowErrorModal(true);
            setShowModal(false);
        }
    }

    return (
        <ModalTemplate showModal={showModal} setShowModal={setShowModal}>
            <h1>Create Group</h1>

            <FormWrapper onSubmit={handleEditGroup}>
                <h3>Group Name</h3>
                <NormalInput ref={groupName} defaultValue={adminData.groups.find(obj => obj._id === group_id).title} placeholder="Enter Group Name" required/>

                <h3>Group Description</h3>
                <NormalInput ref={groupDescription} defaultValue={adminData.groups.find(obj => obj._id === group_id).description} placeholder="Enter Group Description" required/>

                <PrimaryButton type="submit">Save Changes</PrimaryButton>
            </FormWrapper>
        </ModalTemplate>
    )
}

export default EditGroup;

const FormWrapper = styled.form`
    button {
        margin: 20px 0 0;
    }
`;