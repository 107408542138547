import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Checkbox, Dropdown, FormInput } from "../styles/Form.styles.js";
import { FormButton } from "../styles/Button.styles.js";
import styled from "styled-components";
import { getGroupRegister, registerUser } from "../utils/index.js";

import { device } from "../styles/Responsive.style.js";

const cookies = new Cookies();

const RegisterUser = () => {
    const [errMsg, setErrMsg] = useState("");
    const [requiredData, setRequiredData] = useState([]);
    const [tandcToggle, setTandcToggle] = useState(false);

    const passwordRef = useRef();
    const confirmPassRef = useRef();
    const nameRef = useRef();
    const dobRef = useRef();
    const genderRef = useRef();
    const educationRef = useRef();
    const prevTechSkillRef = useRef();
    const stautsRef = useRef();
    const regionRef = useRef();
    const aspirationRef = useRef();
    const termsRef = useRef();

    const navigate = useNavigate();

    const fetchGroup = async () => {
        const res = await getGroupRegister()

        if(res.message === "Group Found"){
            setRequiredData(res.captured_data)
        } else {
            setErrMsg("Something went wrong, please try again later.")
        }
    }

    useEffect(() => {
        fetchGroup()
    }, [])

    const handleRegister = async (e) => {
        e.preventDefault();

        if(passwordRef.current.value !== confirmPassRef.current.value){
            setErrMsg("Passwords must match")
            return;
        }

        if(requiredData.includes("dob")){
            if(new Date(dobRef.current.value).getTime() + 86400000 > new Date().getTime()){
                setErrMsg("Invalid Date Of Birth")
                return;
            }
        }

        if(!termsRef.current.checked){
            setErrMsg("To Sign Up, accept Terms & Conditions");
            return;
        }

        const extra_data = {}
        
        const extraInfoRefs = {
            dob: requiredData.includes("dob") && dobRef.current.value,
            gender: requiredData.includes("gender") && genderRef.current.value,
            education: requiredData.includes("education") && educationRef.current.value,
            prevTechSkill: requiredData.includes("prevTechSkill") && prevTechSkillRef.current.value,
            stauts: requiredData.includes("stauts") && stautsRef.current.value,
            region: requiredData.includes("region") && regionRef.current.value,
            aspiration: requiredData.includes("aspiration") && aspirationRef.current.value,
        }

        requiredData.forEach((data) => {
            extra_data[data] = extraInfoRefs[data]
        })

        let data = await registerUser(nameRef.current.value, passwordRef.current.value, extra_data, new Date() );

        if(data.registered) {
            await cookies.set("token", data.newToken)
            navigate("/questionnaires");
            return;
        } else {
            setErrMsg(data.message);
            return;
        }
    }

    return (
        <RegisterForm onSubmit={handleRegister}>
            <h1>Welcome!</h1>
            <p className="welcomeMsg">As this is your first time logging in, you will need to finish registering.</p>
            <FormInput>      
                <input type="password" placeholder=" " ref={passwordRef} minLength="8" maxLength="30" required />
                <label>Password (8 characters minimum)</label>
            </FormInput>
            <FormInput>   
                <input type="password" placeholder=" " ref={confirmPassRef} minLength="8" maxLength="30" required />
                <label>Confirm Password</label>
            </FormInput>
            <FormInput>   
                <input type="text" placeholder=" " ref={nameRef} required />
                <label>Full Name</label>
            </FormInput>

            {requiredData.includes("dob") && (
                        <FormInput>   
                            <input type="date" placeholder=" " ref={dobRef} required />
                            <label>Date of Birth</label>
                        </FormInput>
                    )}
                    {requiredData.includes("gender") && (
                        <Dropdown>
                            <select ref={genderRef} required>
                                <option value="">Gender</option>
                                <option value="Male">Male</option> 
                                <option value="Female">Female</option>
                                <option value="Prefer not to say">Prefer not to say</option>
                            </select>
                        </Dropdown> 
                    )}
                    {requiredData.includes("education") && (
                        <Dropdown>
                            <select ref={educationRef} required>
                                <option value="">Highest Form of Education</option>
                                <option value="Level 1">Level 1 (GCSE Grade D - G)</option> 
                                <option value="Level 2">Level 2 (GCSE Grade A* - C)</option>  
                                <option value="Level 3">Level 3 (AS and A levels)</option>  
                                <option value="Level 4">Level 4 (BTEC Professional diplomas)</option>  
                                <option value="Level 5">Level 5 (Foundation degrees)</option>  
                                <option value="Level 6">Level 6 (Bachelor's degrees)</option>
                                <option value="Level 7">Level 7 (Master's degrees)</option>    
                                <option value="Level 8">Level 8 (Doctoral degrees)</option>  
                                <option value="None">No qualifications</option>      
                            </select>
                        </Dropdown>
                    )}
                    {requiredData.includes("prevTechSkill") && (
                        <Dropdown>
                            <select ref={prevTechSkillRef} required>
                                <option value="">Previous skills in tech</option>
                                <option value="Never">Never been in tech</option>
                                <option value="Little">A little experience in tech</option> 
                                <option value="Experienced">Experienced in tech</option> 
                            </select>
                        </Dropdown>
                    )}
                    {requiredData.includes("status") && (
                        <Dropdown>
                            <select ref={stautsRef} required>
                                <option value="">Status</option>
                                <option value="Unemployed">Unemployed</option> 
                                <option value="Full-Time">Full-Time</option>
                                <option value="Part-Time">Part-Time</option>
                                <option value="Self-Employed">Self-Employed</option>
                                <option value="Secondary Education">Secondary Education</option>
                                <option value="College">College</option>
                                <option value="College">University</option>
                            </select>
                        </Dropdown>
                    )}
                    {requiredData.includes("region") && (
                        <Dropdown>
                            <select ref={regionRef} required>
                                <option value="">Region</option>
                                <option value="Bedfordshire">Bedfordshire</option> 
                                <option value="Buckinghamshire">Buckinghamshire</option>
                                <option value="Cambridgeshire">Cambridgeshire</option>
                                <option value="Cheshire">Cheshire</option> 
                                <option value="Cleveland">Cleveland</option>               
                                <option value="Cornwall">Cornwall</option>      
                                <option value="Cumbria">Cumbria</option>      
                                <option value="Derbyshire">Derbyshire</option>      
                                <option value="Devon">Devon</option>      
                                <option value="Dorset">Dorset</option>      
                                <option value="Durham">Durham</option>      
                                <option value="East Sussex">East Sussex</option>

                                <option value="Essex">Essex</option>
                                <option value="Gloucestershire">Gloucestershire</option>
                                <option value="Greater London">Greater London</option>
                                <option value="Greater Manchester">Greater Manchester</option>
                                <option value="Hampshire">Hampshire</option>
                                <option value="Hertfordshire">Hertfordshire</option>
                                <option value="Kent">Kent</option>
                                <option value="Lancashire">Lancashire</option>
                                <option value="Leicestershire">Leicestershire</option>
                                <option value="Lincolnshire">Lincolnshire</option>
                                <option value="Merseyside">Merseyside</option>
                                <option value="Norfolk">Norfolk</option>
                                <option value="North Yorkshire">North Yorkshire</option>
                                <option value="Northamptonshire">Northamptonshire</option>
                                <option value="Northumberland">Northumberland</option>
                                <option value="Nottinghamshire">Nottinghamshire</option>
                                <option value="Oxfordshire">Oxfordshire</option>
                                <option value="Shropshire">Shropshire</option>
                                <option value="Somerset">Somerset</option>
                                <option value="South Yorkshire">South Yorkshire</option>
                                <option value="Staffordshire">Staffordshire</option>
                                <option value="Suffolk">Suffolk</option>
                                <option value="Surrey">Surrey</option>
                                <option value="Tyne and Wear">Tyne and Wear</option>
                                <option value="Warwickshire">Warwickshire</option>
                                <option value="West Berkshire">West Berkshire</option>
                                <option value="West Midlands">West Midlands</option>
                                <option value="West Sussex">West Sussex</option>
                                <option value="West Yorkshire">West Yorkshire</option>
                                <option value="Wiltshire">Wiltshire</option>
                                <option value="Worcestershire">Worcestershire</option>

                                <option value="Flintshire">Flintshire</option>
                                <option value="Glamorgan">Glamorgan</option>
                                <option value="Merionethshire">Merionethshire</option>
                                <option value="Monmouthshire">Monmouthshire</option>
                                <option value="Montgomeryshire">Montgomeryshire</option>
                                <option value="Pembrokeshire">Pembrokeshire</option>
                                <option value="Radnorshire">Radnorshire</option>
                                <option value="Anglesey">Anglesey</option>
                                <option value="Breconshire">Breconshire</option>
                                <option value="Caernarvonshire">Caernarvonshire</option>
                                <option value="Cardiganshire">Cardiganshire</option>
                                <option value="Carmarthenshire">Carmarthenshire</option>
                                <option value="Denbighshire">Denbighshire</option>

                                <option value="Aberdeen City">Aberdeen City</option>
                                <option value="Aberdeenshire">Aberdeenshire</option>
                                <option value="Angus">Angus</option>
                                <option value="Argyll and Bute">Argyll and Bute</option>
                                <option value="City of Edinburgh">City of Edinburgh</option>
                                <option value="Clackmannanshire">Clackmannanshire</option>
                                <option value="Dumfries and Galloway">Dumfries and Galloway</option>
                                <option value="Dundee City">Dundee City</option>
                                <option value="East Ayrshire">East Ayrshire</option>
                                <option value="East Dunbartonshire">East Dunbartonshire</option>
                                <option value="East Lothian">East Lothian</option>
                                <option value="East Renfrewshire">East Renfrewshire</option>
                                <option value="Eilean Siar">Eilean Siar</option>
                                <option value="Falkirk">Falkirk</option>
                                <option value="Fife">Fife</option>
                                <option value="Glasgow City">Glasgow City</option>
                                <option value="Highland">Highland</option>
                                <option value="Inverclyde">Inverclyde</option>
                                <option value="Midlothian">Midlothian</option>
                                <option value="Moray">Moray</option>
                                <option value="North Ayrshire">North Ayrshire</option>
                                <option value="North Lanarkshire">North Lanarkshire</option>
                                <option value="Orkney Islands">Orkney Islands</option>
                                <option value="Perth and Kinross">Perth and Kinross</option>
                                <option value="Renfrewshire">Renfrewshire</option>
                                <option value="Scottish Borders">Scottish Borders</option>
                                <option value="Shetland Islands">Shetland Islands</option>
                                <option value="South Ayrshire">South Ayrshire</option>
                                <option value="South Lanarkshire">South Lanarkshire</option>
                                <option value="Stirling">Stirling</option>
                                <option value="West Dunbartonshire">West Dunbartonshire</option>
                                <option value="West Lothian">West Lothian</option>
                                <option value="Antrim">Antrim</option>
                                <option value="Armagh">Armagh</option>
                                <option value="Down">Down</option>
                                <option value="Fermanagh">Fermanagh</option>
                                <option value="Derry and Londonderry">Derry and Londonderry</option>
                                <option value="Tyrone">Tyrone</option>                
                                <option value="Other">Other</option>      
                            </select>
                        </Dropdown>
                    )}
                    {requiredData.includes("aspiration") && (
                        <Dropdown>
                            <select ref={aspirationRef} required>
                                <option value="">What best describes you?</option>
                                <option value="No idea whether tech is for me">No idea whether tech is for me</option> 
                                <option value="Interested in tech">Interested in tech</option> 
                                <option value="Considering returning to work and this could be an option">Considering returning to work and this could be an option</option> 
                                <option value="trying to figure out which training I should get">trying to figure out which training I should get</option> 
                            </select>
                        </Dropdown>
                    )}

                    <p id="tandc" className={tandcToggle ? "" : "tandc-closed"}>I, hereby acknowledge that I have read, understood, and agree to the processing of my personal data by digiDNA, a platform operated by Code Nation, and in accordance with the provisions of the General Data Protection Regulation (GDPR).
I understand that my personal data may be collected, used, stored, and processed by Code Nation for the purposes of providing services, maintaining customer accounts, communicating with me, and fulfilling legal obligations.
I understand that I have the right to access, rectify, or erase my personal data held by Code Nation, as well as the right to restrict or object to its processing. I also understand that I have the right to data portability.
I consent to the transfer of my personal data to third parties, as necessary, for the purposes outlined above, provided such transfer is conducted in compliance with applicable data protection laws.
I understand that I have the right to withdraw my consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.
By ticking the box below, I confirm that I am of legal age and have the authority to provide consent for the processing of my personal data.</p>
                    <p id="readMoreBtn" onClick={() => setTandcToggle(!tandcToggle)}>{tandcToggle? "See Less":"Read More"}</p>
                    <Checkbox htmlFor="LancsCheck">
                        <input ref={termsRef} type="checkbox" id="LancsCheck" />
                        I accept the Terms & Conditions
                        <div className="customCheckbox"></div>
                    </Checkbox>
            <ErrorMessage>{errMsg}</ErrorMessage>
            <FormButton type="submit">Register</FormButton>
        </RegisterForm>
    )
}

export default RegisterUser;

const RegisterForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: white;
    padding: 50px;
    border-radius: 5px;

    h1 {
        margin: 0;
        font-size: 48px;
    }

    .welcomeMsg {
        font-size: 18px;
        width: 300px;
        margin: 0 0 20px;
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    input, select {
        margin: 5px 0;
    }

    & > div {
        margin: 0;
    }

    .tandc-closed {
        overflow: hidden;
        white-space: nowrap;
    }

    #tandc {
        background-color: var(--secondary-gray);
        padding: 10px;
        border-radius: 5px;
        border: 1px solid black;
        text-overflow: ellipsis;
        width: 350px;
        box-sizing: border-box;
        margin-bottom: 0;
    }

    #readMoreBtn {
        margin-top: 5px;
        color: var(--blue);
        cursor: pointer;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;